import React from 'react'

import { Card, Table, Modal, message, Divider, Popconfirm, Tabs, Button, Input, Form, Select } from 'antd'
import { FetchNews, DeleteNews, CreateNews } from "../../service/api"
import moment from 'moment'
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

// import './index.css'

export default class News extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            newsType: 0,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
        }
    }

    componentDidMount() {
      this.fetchMessage(this.state.newsType, this.state.pageNum, this.state.pageSize)
    }

    fetchMessage= (newsType, pageNum, pageSize) => {
      const param = {
        pageNum: pageNum,
        pageSize: pageSize,
        newsType: newsType,
      }
      return FetchNews(param).then(res => {
        if (res.code == 200) {
            console.log(res)
            this.setState({
              data: res.data.data,
              pageNum: res.data.pageNum,
              total: res.data.total
            })
        } else {
            message.error(res.message)
        }
      }).catch(err => {
          console.log(err)
      })
    }

    _initColumn = () => {
        const columns = [
            {
              title: '展示时间',
              dataIndex: 'showTime',
              width: '10%',
            },
            {
              title: '显示标题',
              dataIndex: 'name',
              width: '30%',
            },
            {
              title: '添加时间',
              dataIndex: 'createTime',
              width: '15%',
              render: (text, record) => {
                var disptime = moment(record.createTime).format("YYYY-MM-DD HH:mm")
                return disptime
              }
            },
            {
                title: '操作',
                key: 'action',
                width: '10%',
                render: (text, record) => (
                  <span>
                    <a target="_blank" href={record.url}>链接</a>
                    <Divider type="vertical" />
                    <Popconfirm
                    title="确认要删除吗？"
                    onConfirm={() => {
                        this.toDelete(record.id);
                    }}
                    onCancel={this.cancel}
                    okText="确定"
                    cancelText="取消"
                    >
                    <a>删除</a>
                    </Popconfirm>
                  </span>
                ),
              },
          ];
        return columns;
    }
    showDetails(record) {
    }
    toDelete(id) {
      const param = {
        id: id
      }
      DeleteNews(param).then(rsp => {
        this.fetchMessage(this.state.newsType, this.state.pageNum, this.state.pageSize)
      })
    }
    handleOk() {
        this.setState({
            visible: false
        })
    }
    changePage(e) {
      console.log(e)
      const pageNum = e.current

      this.fetchMessage(this.state.newsType, pageNum, this.state.pageSize)
    }

    addNews() {
      this.setState({
        visible: true
      })
    }

    tabChange = (key) => {
      this.fetchMessage(parseInt(key), this.state.pageNum, this.state.pageSize).then(rsp => {
        this.setState({
          newsType: parseInt(key),
        })
      })
    }

    handleClose() {
      this.setState({
        visible: false,
      })
    }
    submit() {
      // console.log(this.cityForm.props.form.getFieldsValue())
      const param = this.cityForm.props.form.getFieldsValue()
      param.newsType = parseInt(param.newsType)
      CreateNews(param).then(rsp => {
        this.handleClose()
        this.fetchMessage(this.state.newsType, this.state.pageNum, this.state.pageSize)
      })
      // console.log('Success:', values);
    }
    render() {
        const columns = this._initColumn()
        const { data, newsType, pageNum, pageSize, total } = this.state
        console.log(this.state.total)
        return (
            <div>
                <Card>
                <Button type="primary" onClick={() => {this.addNews()}}>新增</Button>
                <Tabs defaultActiveKey={"0"} onChange={this.tabChange}>
                  <TabPane tab="公司新闻" key="0">
                    <Table columns={columns} rowKey={(record) => record.id} dataSource={data} pagination={{simple: true, current:pageNum, pageSize:pageSize ,total:total, }} onChange={this.changePage.bind(this)} />
                  </TabPane>
                  <TabPane tab="行业新闻" key="1">
                    <Table columns={columns} rowKey={(record) => record.id} dataSource={data} pagination={{simple: true, current:pageNum, pageSize:pageSize ,total:total, }} onChange={this.changePage.bind(this)} />
                  </TabPane>
                  <TabPane tab="更新日志" key="2">
                    <Table columns={columns} rowKey={(record) => record.id} dataSource={data} pagination={{simple: true, current:pageNum, pageSize:pageSize ,total:total, }} onChange={this.changePage.bind(this)} />
                  </TabPane>
                </Tabs>
                </Card>
                <Modal
                    title="添加新闻"
                    visible={this.state.visible}
                    onOk={this.submit.bind(this)}
                    onCancel={this.handleClose.bind(this)}
                >
                  <NewsFormInst  wrappedComponentRef={(inst)=>{this.cityForm = inst}}></NewsFormInst>
                </Modal>
            </div>
        )
    }
}

class NewsForm extends React.Component {

  constructor(props) {
    super(props)
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol:{
        span:5
      },
      wrapperCol:{
       span:19
      }
    }
    return (
      <Form
        name="basic"
      >
        <Form.Item label="新闻类别" name="newsType" {...formItemLayout}>
            {getFieldDecorator('newsType', {
                rules: [{ required: true, message: '选择新闻类比!' }],
            })(
              <Select>
                <Option value="0">公司新闻</Option>
                <Option value="1">行业新闻</Option>
                <Option value="2">更新日志</Option>
             </Select>
            )}
        </Form.Item>
        <Form.Item label="显示时间" {...formItemLayout}>
            {getFieldDecorator('showTime', {
                rules: [{ required: true, message: '请填写新闻时间!' }],
            })(
                <Input
                    // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="显示日期"
                    // size="large"
                />
            )}
        </Form.Item>
        <Form.Item  label="新闻标题" {...formItemLayout}>
            {getFieldDecorator('name', {
                rules: [{ required: true, message: '请填写新闻标题' }],
            })(
                <Input
                    // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    // type="password"
                    placeholder="新闻标题"
                    // size="large"
                    // style={{ marginRight: 10}}
                />
            )}
        </Form.Item>
        <Form.Item  label="新闻链接" {...formItemLayout}>
            {getFieldDecorator('url', {
                rules: [{ required: true, message: '请输入新闻链接' }],
            })(
                <Input
                    // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    // type="password"
                    placeholder="新闻链接"
                    // style={{ marginRight: 10}}
                />
            )}
        </Form.Item>
      </Form>
    )
  }
}
const NewsFormInst = Form.create({})(NewsForm);
