import React, { Component, Fragment } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './pages/login'
import Index from './pages/index'

import './App.css'

class App extends Component {

  render() {
    return (
      <Router>
        <Fragment>
          <Route path="/login" component={Login}></Route>
          <Route path="/home" component={Index}></Route>
        </Fragment>
      </Router>
    )
  }
}


export default App;
