import React from 'react'
import { Upload, message, Button, Icon, Card, Modal } from 'antd';
// import { Upload, Icon, message } from 'antd';
import { UploadUrl, UpdateSliders, FetchSliders, DeleteSliders } from "../../service/api"
import moment from 'moment'
import './index.css'


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class Sliders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          previewVisible: false,
          previewImage: '',
          fileList: [
          ],
        }
    }

    componentDidMount() {
        //fetch file list from server
        this.fetchSliders()
    }

    fetchSliders = () => {
      FetchSliders().then(rsp => {
        console.log(rsp)
        if (rsp.code == 200) {
          const filelist = rsp.data
          for(let i = 0; i < filelist.length; i++) {
            filelist[i].uid = filelist[i].id
          }
          this.setState({
            fileList: filelist
          })
        }
      })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
  
      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
      });
    };
  
    handleChange = (info) => { 
      console.log(info)
      if (info.file && info.file.status == "done") {
        const rsp = info.file.response
        const param = {
          id: rsp.id,
          url: rsp.url,
        }
        UpdateSliders(param).then(rsp => {
          console.log(rsp)
        })
      }
      this.setState({ fileList: info.fileList })
    };

    deleteFile = (file) => {
      const param = {
        id: file.id
      }
      return DeleteSliders(param).then(rsp => {
        return true
      })

    }

    geneId = () => {
      return moment.now().valueOf();
    }
    render() {
      
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>
        );
        return (
            <Card>
              <div className="clearfix">
                <Upload
                  action={UploadUrl}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  onRemove={this.deleteFile}
                  data={{id: this.geneId(), picType:"slider"}}
                >
                  {fileList.length >= 5 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </div>
            </Card>
        )
    }
}
