import React from 'react'
import { Upload, Icon, Button, Input,Modal, Tabs,TextInput } from 'antd';
import moment from 'moment'
import { CreateProduct, UploadUrl,UploadFileUrl } from "../../service/api"
import { StyleSheet } from 'react'
// import "./index.css"

const { TextArea } = Input;
const { TabPane } = Tabs;

function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}


export default class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            id: -1,
            name: "",
            serialNo: "",
            description: "",
            details: "",
            images: [],
            features: [],
            params: [],
            manual: [],
            software: [],
            fileList: [],
        }
    }

    componentDidMount() {
        const { product } = this.props
        
        this._initProduct(product)
    }

    jsonParse = (str) => {
        if (str && str != "") {
            return JSON.parse(str)
        }
        return []
    }
    _initProduct = (product) => {
        console.log("receive product ", product)
        if (!product) {
            return
        }
        this.setState({
            id: product.id,
            name: product.name,
            serialNo: product.serialNo,
            description: product.description,
            details: product.details,
            images: this.jsonParse(product.images),
            features: this.jsonParse(product.features),
            params: this.jsonParse(product.params),
            manual: this.jsonParse(product.manual),
            software: this.jsonParse(product.software),
            fileList: this.jsonParse(product.images),
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && this.props != nextProps) {
            const { product } = nextProps
            this._initProduct(product)
        }
    }


    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
        });
    };

    newProductImages = (fileList) => {
        const images = []
        for (let i = 0; i < fileList.length; i++) {
            const rsp = fileList[i].response
            if (rsp) {
                images.push({
                    uid: rsp.id,
                    id: rsp.id,
                    url: rsp.url
                })
            } else {
                images.push(fileList[i])
            }
            
        }
        return images

    }
    handleChange = (info) => { 
        this.setState({ fileList: info.fileList })
        
    };

    changeTab = (key) => {
        this.setState({
            curTab: key
        })
    }

    deleteFile = (file) => {
        const param = {
          id: file.id
        }
        // return DeleteSliders(param).then(rsp => {
        //   return true
        // })
  
    }

    geneId = () => {
        return moment.now().valueOf();
      }

    featureChange = (opt, param) => {
        
        if (opt == "delete") {
            const id = param
            let features = this.state.features
            let index = -1
            for (let i = 0; i < features.length; i++) {
                if (features[i].id == id) {
                    index = i
                    break;
                }
            }
            features.splice(index, 1)
            this.setState({
                features
            })
        } else if (opt == "edit") {
            let features = this.state.features
            for (let i = 0; i < features.length; i++) {
                if (features[i].id == param.id) {
                    features[i].title = param.title
                    features[i].details = param.details
                    features[i].imageUrl = param.imageUrl
                    break;
                }
            }
            this.setState({
                features
            })
        }
    }

    addOneFeature = () => {
        const id = moment.now().valueOf();
        const item = {
            id: id,
        }
        this.setState(prev => ({
            features: prev.features.concat(item)
        }))
    }

    addOneParam = () => {
        const id = moment.now().valueOf();
        const pid = moment.now().valueOf();
        const obj = {
            id: id,
            title: "",
            params: [{id: pid, key:"", val:[""]}]
        }
        const paramArr = this.state.params
        paramArr.push(obj)
        this.setState({
            params: paramArr
        })
    }

    submit = () => {

        console.log("params...", this.state.params)
        const software = this.state.software.map(item => {
            if (item.response) {
               return {
                    name: item.response.name,
                    url: item.response.url,
                    uid: item.response.name,
                }
            }
            return item
        })

        const manual = this.state.manual.map(item => {
            if (item.response) {
               return {
                    name: item.response.name,
                    url: item.response.url,
                    uid: item.response.name,
                }
            }
            return item
        })

        const images = this.newProductImages(this.state.fileList)

        console.log("images...", images)

        const obj = {
            id: this.state.id,
            name: this.state.name,
            serialNo: this.state.serialNo,
            description: this.state.description,
            details: this.state.details,
            images: JSON.stringify(images),
            features: JSON.stringify(this.state.features),
            params: JSON.stringify(this.state.params),
            manual: JSON.stringify(manual),
            software: JSON.stringify(software),
        }
        
        const { refresh } = this.props
        CreateProduct(obj).then(rsp => {
            console.log(rsp)
            refresh()
        })
    }

    uploadManuals = (info) => {
        let manual = [...info.fileList];
        manual = manual.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        this.setState({ manual });
    }
    uploadSoftware = (info) => {
        console.log("file changes")
        let software = [...info.fileList];
        software = software.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        this.setState({ software });
    }

    deleteParam = (index) => {
        const params = this.state.params
        params.splice(index, 1)
        this.setState({
            params
        })
    }

    flushParam = (index, obj) => {
        const params = this.state.params
        params[index] = obj
        this.setState({
            params
        })
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
            <div>
              <Icon type="plus" />
              <div className="ant-upload-text">点我上传</div>
            </div>
          );
        return (
            <div>
                <div style={{marginBottom: 20, width:'100%'}}>
                    <Button type="primary" block size={"large"}  onClick={this.submit}>保存</Button>
                </div>
                <div className="product">
                    <Upload
                        action={UploadUrl}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.deleteFile}
                        data={{id: this.geneId(), picType:"slider"}}
                        >
                    {fileList.length >= 12 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </div>
                <div className="basic_info">

                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>产品型号</span>
                        <Input className="basic_item" placeholder="产品型号" allowClear 
                        defaultValue={this.state.serialNo}
                        value={this.state.serialNo}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                serialNo: value
                            })
                        }} />
                    </div>

                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>产品名称</span>
                        <Input className="basic_item" placeholder="产品名称" allowClear 
                        defaultValue={this.state.name}
                        value={this.state.name}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                name: value
                            })
                        }} />
                    </div>
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>产品简述</span>
                        <TextArea className="basic_item"  placeholder="产品描述" allowClear  autoSize={{ minRows: 3, maxRows: 10 }} 
                            defaultValue={this.state.description}
                            value={this.state.description}
                            onChange={({ target: { value } }) => {
                                // console.log(value)
                                this.setState({
                                    description: value
                                })
                        }}  />
                    </div>

                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>产品详述</span>
                        <TextArea className="basic_item"  placeholder="产品详细描述" allowClear  autoSize={{ minRows: 3, maxRows: 10 }} 
                            defaultValue={this.state.details}
                            value={this.state.details}
                            onChange={({ target: { value } }) => {
                                // console.log(value)
                                this.setState({
                                    details: value
                                })
                        }}  />
                    </div>

                </div>

                <div style={{minHeight: 600}}>
                    <Tabs defaultActiveKey={this.state.curTab} onChange={this.changeTab}>
                        <TabPane tab="产品特性" key="feature">
                            <Button type="primary" style={{marginBottom: 20}} onClick={this.addOneFeature}>添加</Button>
                            {
                                this.state.features.map(item => {
                                   return <OneFeature key={item.id} item={item} callback={this.featureChange}></OneFeature>
                                })
                            }
                        </TabPane>
                        <TabPane tab="设备参数" key="params">
                            <Button type="primary" style={{marginBottom: 20}} onClick={this.addOneParam}>添加参数</Button>
                            {
                                this.state.params.map((item,index) => {
                                    return <OneParam key={item.id} index={index} item={item} deleteParam={this.deleteParam} flushParam={this.flushParam}></OneParam>
                                })
                            }
                        </TabPane>
                        <TabPane tab="资源下载" key="resources">
                            <div style={{display:'flex', flexDirection:'column', }}>
                                <div style={{minHeight: 100, marginBottom: 20,width: '50%',}}>
                                    <span style={{fontWeight:600, fontSize: 16}}>用户手册</span>
                                    <div>
                                        <Upload 
                                            action= {UploadFileUrl}
                                            onChange={this.uploadManuals}
                                            multiple={true}
                                            fileList={this.state.manual}>
                                            <Button>
                                                <Icon type="upload" /> 上传手册
                                            </Button>
                                        </Upload>
                                    </div>
                                </div>
                                <div  style={{minHeight: 100, width: '50%',}}>
                                    <span style={{fontWeight:600, fontSize: 16}}>配套软件</span>
                                    <div >
                                        <Upload 
                                            action= {UploadFileUrl}
                                            onChange={this.uploadSoftware}
                                            multiple={true}
                                            fileList={this.state.software}
                                            >
                                            
                                            <Button>
                                                <Icon type="upload" /> 上传软件
                                            </Button>
                                        </Upload>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

}

class OneFeature extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: -1,
            title: "",
            details: "",
            imageUrl: "",
            fileList: []
        }
    }

    componentDidMount() {
        const {item} = this.props
        this.setState({
            id: item.id,
            title: item.title,
            details: item.details,
            imageUrl: item.imageUrl,
            fileList:[]
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps != this.props) {
            const {item} = nextProps
            this.setState({
                id: item.id,
                title: item.title,
                details: item.details,
            })
        }
    }

    handleChange = (info) => { 
        if (info.file && info.file.status == "done") {
            const rsp = info.file.response
            console.log(info)
            // const img = {
            //     id: rsp.id,
            //     url: rsp.url,
            //     uid: rsp.id,
            // }
            this.setState({
                imageUrl: rsp.url
            }) 
            this.editInfo("imageUrl", rsp.url)           
        }
        this.setState({ fileList: info.fileList })
        
    };

    // changeTab = (key) => {
    //     this.setState({
    //         curTab: key
    //     })
    // }

    deleteFile = (file) => {
        const param = {
          id: file.id
        }
        // return DeleteSliders(param).then(rsp => {
        //   return true
        // })
  
    }

    geneId = () => {
        return moment.now().valueOf();
      }


    editInfo = (key, val) => {
        this.setState({
            [key]: val
        })
        const feature = {
            id: this.state.id,
            title: this.state.title,
            details: this.state.details,
            imageUrl: this.state.imageUrl,
        }
        if (key == "title") {
            feature.title = val
        } else if (key == "details") {
            feature.details = val
        } else if (key == "imageUrl") {
            feature.imageUrl = val
        }

        const { callback } = this.props
        callback("edit", feature) 
        
    }

    deleteOne = () => {
        const { callback } = this.props

        callback("delete", this.state.id)
    }

    render() {
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { imageUrl, fileList } = this.state;
        return (
            <div style={{display:'flex',flexDirection:'row',}}>
                <div className="feature_pic">
                    
                    <Upload
                        action={UploadUrl}
                        listType="picture-card"
                        fileList={fileList}
                        showUploadList={false}
                        // onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.deleteFile}
                        data={{id: this.geneId(), picType:"product"}}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </div>
                <div className="one_feture">
                     <Input className="feature_title" placeholder="特性标题" allowClear 
                        value={this.state.title}
                        onChange={({ target: { value } }) => {
                            this.editInfo("title", value)
                    }} />
                    <TextArea placeholder="特性描述" allowClear  autoSize={{ minRows: 3, maxRows: 10 }} 
                        value={this.state.details}
                        onChange={({ target: { value } }) => {
                        this.editInfo("details", value)
                    }} />
                </div>
                <div style={{width:150, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}}>
                    <Button onClick={this.deleteOne}>删除</Button>
                </div>
            </div>
        )
        
    }
}



class OneParam extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            index: -1,
            id: -1,
            title: "",
            params:[],
        }
    }

    componentDidMount() {
        const {item, index} = this.props
        this.setState({
            index: index,
            id: item.id,
            title: item.title,
            params: item.params,
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps != this.props) {
            const {item, index} = nextProps
            console.log("title: ", item)
            this.setState({
                index,
                id: item.id,
                title: item.title,
                params: item.params,
            })
        }
    }

    geneId = () => {
        return moment.now().valueOf();
      }


    editInfo = (key, val) => {
        this.setState({
            [key]: val
        })
        const feature = {
            id: this.state.id,
            title: this.state.title,
            params: this.state.params,
        }
        if (key == "title") {
            feature.title = val
        } else if (key == "details") {
            feature.details = val
        } else if (key == "imageUrl") {
            feature.imageUrl = val
        }

        const { callback } = this.props
        callback("edit", feature) 
        
    }

    deleteOne = () => {
        const { callback } = this.props

        callback("delete", this.state.id)
    }

    addProperty = () => {
        // [{title: "工作环境", params:[{key:"name tiele", val:["12*12", "下面：13*133"]}] }],
        const param = this.state.params
        const pid = this.geneId()
        const obj = {
            id: pid,
            key: "",
            val: [""],
        }
        param.push(obj)
        this.setState({
            params: param
        })
    }

    deleteProperty = (index) => {
        const cpyParam = this.state.params.slice()
        console.log("before param: ", cpyParam)
        
        const param = this.state.params
        
        const deleted = param.splice(index, 1)
        console.log("after param: ", param)
        console.log("delet param: ", deleted)
        this.setState({
            params: param
        })

    }
    addVal = (parentIndex) => {
        const property = this.state.params[parentIndex]
        if (!property) {
            return
        }
        property.val.push("")
        this.refreshParam()

    }
    deleteVal=(parentIndex)=> {
        const property = this.state.params[parentIndex]
        if (!property) {
            return
        }
        property.val.pop()
        console.log(property)
        this.refreshParam()
    }

    deleteParam = () => {
        const { deleteParam } = this.props
        deleteParam(this.state.index)
    }

    changeTitle = (e) => {
        const val = e.target.value;
        const obj = {
            id: this.state.id,
            title: val,
            params: this.state.params
        }
        this.refreshParam(obj)
    }

    changeParamKey = (index, val) => {
        const params = this.state.params
        params[index].key = val
        const obj = {
            id: this.state.id,
            title: this.state.title,
            params: params
        }
        this.refreshParam(obj)
    }

    changeParamVal = (pindex, idx, val) => {
        const params = this.state.params
        const valArr = params[pindex].val
        valArr[idx] = val
        const obj = {
            id: this.state.id,
            title: this.state.title,
            params: params
        }
        this.refreshParam(obj)
    }

    refreshParam = (obj) => {
        if (!obj) {
            obj = {
                id: this.state.id,
                title: this.state.title,
                params: this.state.params
            }
        }
        const { flushParam } = this.props

        flushParam(this.state.index, obj)
    }

    render() {
        return (
            <div style={{minHeight: 100, width:'80%', marginBottom: 30,}}>
                <div style={{fontWeight: 600, fontSize: 18, marginBottom: 20,}}>
                    <Button style={{marginRight: 10}} icon="minus"  size={"small"} type="primary" shape="circle"  onClick={this.deleteParam}/>
                    <Input placeholder={"名称"} style={{width:200,}} value={this.state.title} onChange={this.changeTitle}/>
                    <Button type="link" onClick={this.addProperty}>添加属性</Button>
                </div>
                
                {
                    this.state.params.map((param, index) => {
                        return (
                            <div key={"prop_" + index}>
                                <div className="param" style={{marginLeft: 20, marginBottom: 10}}>
                                    <Button onClick={() => this.deleteProperty(index)} icon="delete" size={"small"} type={"danger"} shape="circle" style={{marginRight: 10,}}/>
                                    <Input placeholder={"名称"} className="paramKey" value={param.key} onChange={(e) => this.changeParamKey(index, e.target.value)}/>
                                    {
                                        param.val.map((val, idx) => {
                                            return <Input key={"val_" + idx} placeholder={"参数"} className="paramVal" value={val} onChange={(e) => {this.changeParamVal(index, idx, e.target.value)}}/>
                                        })
                                    }
                                    <Button style={{marginLeft: 10}} icon="plus"  type={"link"}  size={"small"} onClick={() => this.addVal(index)}/>
                                    <Button icon="minus"  type={"link"} size={"small"} onClick={() => this.deleteVal(index)}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
        
    }
}
