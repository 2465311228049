import React from 'react'

import {Card, Upload, Icon, Button, Input,Modal, Tabs, message } from 'antd';
import './index.css'

import { CreateAboutUs, FetchAboutUs } from '../../service/api'

const { TextArea } = Input;

export default class Coperation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            address: "",
            tel: "",
            email: "",
            classify: "contacts",
        }
    }

    componentDidMount() {
        FetchAboutUs().then(rsp => {
            if (rsp.code == 200 && rsp.data) {
                for (let i = 0; i < rsp.data.length; i++) {
                    const data = rsp.data[i]
                    if (data.classify == this.state.classify && data.content) {
                        const contacts = JSON.parse(data.content)
                        this.setState({
                            address: contacts.address,
                            tel: contacts.tel,
                            email: contacts.email,
                        })
                    }
                }
            }
        })
    }

    saveData = () => {
        const contacts = {
            address: this.state.address,
            tel: this.state.tel,
            email: this.state.email,
        }
        const param = {
            title: "",
            content: JSON.stringify(contacts),
            classify: this.state.classify,
        }

        CreateAboutUs(param).then(rsp => {
            if (rsp.code == 200) {
                message.success('保存成功');
            } else {
                message.error("保存失败");
            }
        })
    }
    render() {
        return (
            <Card>
                <div className="basic_info">
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>地址</span>
                        <Input className="basic_item" allowClear 
                        defaultValue={this.state.address}
                        value={this.state.address}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                address: value
                            })
                        }} />
                    </div>
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>电话</span>
                        <Input className="basic_item" allowClear 
                        defaultValue={this.state.tel}
                        value={this.state.tel}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                tel: value
                            })
                        }} />
                    </div>
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>邮箱</span>
                        <Input className="basic_item" allowClear 
                        defaultValue={this.state.email}
                        value={this.state.email}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                email: value
                            })
                        }} />
                    </div>
                    <div style={{width:'100%', textAlign:"center", marginTop: 30,}}>
                        <Button type="primary" size="large" onClick={this.saveData}>保存</Button>
                    </div>
                </div>
            </Card>
        )
    }
}