import React from 'react'

import {Card, Upload, Icon, Button, Input,Modal, Tabs, message } from 'antd';
import './index.css'

import { CreateAboutUs, FetchAboutUs, UploadUrl } from '../../service/api'
import moment from 'moment'

const { TextArea } = Input;

export default class career extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: "产品研发团队",
            members: [],
            classify: "teams",
        }
    }

    componentDidMount() {
        FetchAboutUs().then(rsp => {
            if (rsp.code == 200 && rsp.data) {
                for (let i = 0; i < rsp.data.length; i++) {
                    const data = rsp.data[i]
                    if (data.classify == this.state.classify && data.content) {
                        const members = JSON.parse(data.content)
                        this.setState({
                            title: data.title,
                            members: members,
                        })
                    }
                }
            }
        })
    }

    saveData = () => {
        const content = JSON.stringify(this.state.members)

        const param = {
            title: this.state.title,
            content: content,
            classify: this.state.classify,
        }

        CreateAboutUs(param).then(rsp => {
            if (rsp.code == 200) {
                message.success('保存成功');
            } else {
                message.error('保存失败');
            }
            
        })
    }

    editMember = (opt, param) => {
        if (opt == "delete") {
            const id = param
            let features = this.state.members
            let index = -1
            for (let i = 0; i < features.length; i++) {
                if (features[i].id == id) {
                    index = i
                    break;
                }
            }
            console.log("before: ", JSON.stringify(features))
            features.splice(index, 1)
            console.log("after: ", JSON.stringify(features))
            this.setState({
                features
            })
        } else if (opt == "edit") {
            let features = this.state.members
            for (let i = 0; i < features.length; i++) {
                if (features[i].id == param.id) {
                    features[i].name = param.name
                    features[i].details = param.details
                    features[i].imageUrl = param.imageUrl
                    break;
                }
            }
            this.setState({
                members: features
            })
        }
    }
    addOneMember = () => {
        const id = moment.now().valueOf();
        const item = {
            id: id,
        }
        this.setState(prev => ({
            members: prev.members.concat(item)
        }))
    }
    render() {
        return (
            <Card>
                <div className="basic_info">
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>标题</span>
                        <Input className="basic_item" allowClear 
                        defaultValue={this.state.title}
                        value={this.state.title}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                title: value
                            })
                        }} />
                    </div>

                    <Button icon="plus" style={{marginTop: 40}} type={"danger"} block ghost onClick={this.addOneMember}>成员</Button>
                    <div style={{marginTop: 40,}}>
                        {
                            this.state.members.map(item => {
                                return <OneMember key={item.id} item={item} callback={this.editMember.bind(this)}></OneMember>
                            })
                        }
                    </div>
                    <div style={{width:'100%', textAlign:"center", marginTop: 30,}}>
                        <Button type="primary" size="large" onClick={this.saveData}>保存</Button>
                    </div>
                </div>
            </Card>
        )
    }
}



class OneMember extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: -1,
            name: "",
            details: "",
            imageUrl: "",
            fileList: []

        }
    }

    componentDidMount() {
        const {item} = this.props
        this.setState({
            id: item.id,
            name: item.name,
            details: item.details,
            imageUrl: item.imageUrl,
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps != this.props) {
            const {item} = nextProps
            this.setState({
                id: item.id,
                name: item.name,
                details: item.details,
                imageUrl: item.imageUrl,
            })
        }
    }

    handleChange = (info) => { 
        if (info.file && info.file.status == "done") {
            const rsp = info.file.response
            console.log(info)
            this.setState({
                imageUrl: rsp.url
            }) 
            this.editInfo("imageUrl", rsp.url)           
        }
        this.setState({ fileList: info.fileList })
        
    };

    deleteFile = (file) => {
        const param = {
          id: file.id
        }
        // return DeleteSliders(param).then(rsp => {
        //   return true
        // })
  
    }

      geneId = () => {
        return moment.now().valueOf();
      }

    editInfo = (key, val) => {
        this.setState({
            [key]: val
        })
        const feature = {
            id: this.state.id,
            name: this.state.name,
            details: this.state.details,
            imageUrl: this.state.imageUrl,
        }
        if (key == "name") {
            feature.name = val
        } else if (key == "details") {
            feature.details = val
        } else if (key == "imageUrl") {
            feature.imageUrl = val
        }

        const { callback } = this.props
        callback("edit", feature)  
    }


    deleteOne = () => {
        const { callback } = this.props
        callback("delete", this.state.id)
    }

    render() {
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { imageUrl, fileList } = this.state;
        return (
            <div style={{display:'flex',flexDirection:'row', marginBottom: 30,}}>
                <div>
                    <Upload
                        action={UploadUrl}
                        listType="picture-card"
                        fileList={fileList}
                        showUploadList={false}
                        // onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.deleteFile}
                        data={{id: this.geneId(), picType:"product"}}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </div>
                <div className="one_feture">
                     <Input className="feature_title" placeholder="特性标题" allowClear 
                        value={this.state.name}
                        onChange={({ target: { value } }) => {
                            this.editInfo("name", value)
                    }} />
                    <TextArea placeholder="特性描述" allowClear  autoSize={{ minRows: 3, maxRows: 10 }} 
                        value={this.state.details}
                        onChange={({ target: { value } }) => {
                        this.editInfo("details", value)
                    }} />
                </div>
                <div style={{width:150, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}}>
                    <Button onClick={this.deleteOne}>删除</Button>
                </div>
            </div>
        )
        
    }
}