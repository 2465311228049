// import React from 'react'

// import { Card} from 'antd'

import React, { Component } from 'react';
import { Form, Input, Button, Card, message, Select } from 'antd';
// import { addUser } from '../../service/apis';
import { UpdateUserPwd } from '../../service/api'

const { Option } = Select;

export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "admin",
            password: "",
        }
      }
      
      save() {
        const obj = {
            username: this.state.username,
            password: this.state.password
        }
        UpdateUserPwd(obj).then(res => {
            message.info("更新成功")
        })
      }
    changeUserName(e) {
        const { value } = e.target;
        this.setState({
            username: value
        })
    }
    
      changePassword(e) {
        const { value } = e.target;
        this.setState({
            password: value
        })

      }
    
      render() {
    
        return (
            <Card>
                <div  style={{marginTop: 30}}>
                    <span style={{fontSize: 18, fontWeight:'bold', marginLeft: 10}}>账号 </span>
                    <Input style={{width: 200, marginLeft: 10}} value={this.state.username}
                        onChange={this.changeUserName.bind(this)}
                        disabled
                    />
                </div>
                <div style={{marginTop: 30}}>
                    <span style={{fontSize: 18, fontWeight:'bold', marginLeft: 10}}>密码 </span>
                    <Input style={{width: 200, marginLeft: 10}} value={this.state.password}
                        onChange={this.changePassword.bind(this)}
                    />
                </div>
                
                <Button type="primary" style={{marginTop: 40,}} onClick={this.save.bind(this)}>保存</Button>
            </Card>
        )
    }
}