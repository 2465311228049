import React from 'react'

import { Card, Table, Modal, message } from 'antd'
import { FetchMessages } from "../../service/api"
import moment from 'moment'

import './index.css'

export default class Message extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: []
        }
    }

    componentDidMount() {
      this.fetchMessage(this.state.pageNum, this.state.pageSize)
    }

    fetchMessage= (pageNum, pageSize) => {
      const param = {
        pageNum: pageNum,
        pageSize: pageSize,
      }
      FetchMessages(param).then(res => {
        if (res.code == 200) {
            console.log(res)
            this.setState({
              data: res.data.data,
              pageNum: res.data.pageNum,
              total: res.data.total
            })
        } else {
            message.error(res.message)
        }
      }).catch(err => {
          console.log(err)
      })
    }

    _initColumn = () => {
        const columns = [
            {
              title: '姓名',
              dataIndex: 'name',
              width: '15%',
            },
            {
              title: '联系方式',
              dataIndex: 'contacts',
              width: '15%',
            },
            {
                title: '内容',
                dataIndex: 'content',
                width: '30%',
                render: (text, record) => (
                    <span className="contentCss">
                        { text && text.length > 100 ? text.substring(0, 99) + "..." : text}
                    </span>
                  ),
            },
            {
              title: '时间',
              dataIndex: 'createTime',
              width: '15%',
              render: (text, record) => {
                var disptime = moment(record.createTime).format("YYYY-MM-DD HH:mm")
                return disptime
              }
            },
            {
                title: '操作',
                key: 'action',
                width: '10%',
                render: (text, record) => (
                  <span>
                    <a onClick={() => this.showDetails(record)}>详情</a>
                  </span>
                ),
              },
          ];
        return columns;
    }
    showDetails(record) {
        Modal.info({
            title: '客户留言',
            content: (
              <div className="infoModal" style={{marginTop: 10}}>
                <p><span style={{fontWeight:"bold"}}>姓名：</span>{record.name}</p>
                <p><span style={{fontWeight:"bold"}}>联系方式：</span>{record.phone}</p>
                <p style={{textAlign:"left"}}><span style={{fontWeight:"bold"}}>内容：</span>{record.content}</p>
              </div>
            ),
            onOk() {},
          });
    }
    handleOk() {
        this.setState({
            visible: false
        })
    }
    changePage(e) {
      console.log(e)
      const pageNum = e.current

      this.fetchMessage(pageNum, this.state.pageSize)
    }
    render() {
        const columns = this._initColumn()
        const { data, pageNum, pageSize, total } = this.state
        console.log(this.state.total)
        return (
            <div>
                <Card>
                    <h1>留言板</h1>
                    <Table columns={columns} rowKey={(record) => record.id} dataSource={data} pagination={{simple: true, current:pageNum, pageSize:pageSize ,total:total, }} onChange={this.changePage.bind(this)} />
                </Card>
            </div>
        )
    }
}