import React from 'react'

import {Card, Upload, Icon, Button, Input,Modal, Tabs,message } from 'antd';
import './index.css'

import { CreateAboutUs, FetchAboutUs } from '../../service/api'

const { TextArea } = Input;

export default class career extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: "职位招聘",
            content: "",
            classify: "career",
            url: "",
        }
    }

    componentDidMount() {
        FetchAboutUs().then(rsp => {
            if (rsp.code == 200 && rsp.data) {
                for (let i = 0; i < rsp.data.length; i++) {
                    const data = rsp.data[i]
                    if (data.classify == this.state.classify) {
                        this.setState({
                            title: data.title,
                            content: data.content,
                            url: data.url,
                        })
                    }
                }
            }
        })
    }

    saveData = () => {
        const param = {
            title: this.state.title,
            url: this.state.url,
            content: this.state.content,
            classify: this.state.classify,
        }

        CreateAboutUs(param).then(rsp => {
            if (rsp.code == 200) {
                message.success('保存成功');
            } else {
                message.error("保存失败");
            }
            
        })
    }
    render() {
        return (
            <Card>
                <div className="basic_info">
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>标题</span>
                        <Input className="basic_item" allowClear 
                        defaultValue={this.state.title}
                        value={this.state.title}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                title: value
                            })
                        }} />
                    </div>
                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>链接</span>
                        <Input className="basic_item" allowClear 
                        placeholder="https://tuthinking.com"
                        defaultValue={this.state.url}
                        value={this.state.url}
                        onChange={({ target: { value } }) => {
                            // console.log(value)
                            this.setState({
                                url: value
                            })
                        }} />
                    </div>

                    <div className="item_line">
                        <span style={{width: 80, fontSize:15, paddingTop:5}}>内容</span>
                        <TextArea className="basic_item"  placeholder="公司简介信息" allowClear  autoSize={{ minRows: 12, maxRows: 30 }} 
                            defaultValue={this.state.content}
                            value={this.state.content}
                            onChange={({ target: { value } }) => {
                                // console.log(value)
                                this.setState({
                                    content: value
                                })
                        }}  />
                    </div>
                    <div style={{width:'100%', textAlign:"center", marginTop: 30,}}>
                        <Button type="primary" size="large" onClick={this.saveData}>保存</Button>
                    </div>
                </div>
            </Card>
        )
    }
}