import React from 'react'

import { Card} from 'antd'

export default class CreateTask extends React.Component {
    render() {
        return (
            <Card>
                <div> 这是内容 </div>
                <div> 这是内容 </div>
                <br></br>
                <div> 这是内容 </div>

            </Card>
        )
    }
}