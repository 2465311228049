import React from 'react'
import { Route, Redirect, } from 'react-router-dom'
// import { isAuthenticated } from '../../utils/Session'

const isLogin = () => {
  return true
}
const PrivateRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={(props) => (
    isLogin()
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: {from: props.location}
      }}/>
  )}/>
)

export default PrivateRoute