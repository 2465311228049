
import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from '../../components/privateRoute'
// import HomePage from '../home'
import HomePage from '../createTask'
import CreateTask from '../createTask'
import AboutUs from '../aboutUs/company'
import Career from '../aboutUs/career'
import Coperation from '../aboutUs/coperation'
import Contacts from '../aboutUs/contacts'
import Teams from  '../aboutUs/teams'
import Account from '../account'
import Messages from '../messages'
import News from '../news'
// import News from '../news'
import Sketch from '../sketch'
import Sliders from '../sliders'
import { Button } from 'antd'

class Content extends React.Component {
    render() {
        return (
            <div style={{padding: 16, position:'relative'}}>
                <Switch>
                    {/* <PrivateRoute exact path='/home' component={HomePage}></PrivateRoute> */}
                    <PrivateRoute exact path='/home' component={Sliders}></PrivateRoute>
                    <PrivateRoute exact path='/home/products' component={Sketch}></PrivateRoute>
                    <PrivateRoute exact path='/home/aboutUs/company' component={AboutUs}></PrivateRoute>
                    <PrivateRoute exact path='/home/aboutUs/contacts' component={Contacts}></PrivateRoute>
                    <PrivateRoute exact path='/home/aboutUs/coperation' component={Coperation}></PrivateRoute>
                    <PrivateRoute exact path='/home/aboutUs/teams' component={Teams}></PrivateRoute>
                    <PrivateRoute exact path='/home/aboutUs/career' component={Career}></PrivateRoute>
                    <PrivateRoute exact path='/home/news' component={News}></PrivateRoute>
                    <PrivateRoute exact path='/home/messages' component={Messages}></PrivateRoute>
                    <PrivateRoute exact path='/home/account' component={Account}></PrivateRoute>
                    {/* <PrivateRoute exact path='/home/tasks/create' component={CreateTask}></PrivateRoute>
                    <PrivateRoute exact path='/home/tasks/list' component={CreateTask}></PrivateRoute> */}

                </Switch>
            </div>
        );
    }
}

export default Content