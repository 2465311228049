import React from 'react'
import {Layout, Menu, Icon, Select, Button} from 'antd'
import { render } from 'react-dom'
import CustomMenu from '../../components/sliderMenu'
import ContentMain from  '../content'
import Cookies from 'js-cookie'
import logo from '../../images/logo.ico';

const { SubMenu } = Menu;
const { Option } = Select;

const {Sider, Header, Content, Footer} = Layout


const menus = [
    // {
    //   title: '首页',
    //   icon: 'home',
    //   key: '/home'
    // },
    {
      title: '轮播图',
      icon: 'sliders',
      key: '/home'
    },
    {
      title: '产品信息',
      icon: 'sketch',
      key: '/home/products',
    },
    {
      title: '在线留言',
      icon: 'message',
      key: '/home/messages'
    },
    {
      title: '新闻中心',
      icon: 'twitter',
      key: '/home/news'
    },
    {
      title: '关于我们',
      icon: 'info-circle-o',
      key: '/home/aboutUs',
      subs: [
        {key: '/home/aboutUs/company', title: '公司简介', icon: '',},
        {key: '/home/aboutUs/contacts', title: '联系信息', icon: '',},
        {key: '/home/aboutUs/coperation', title: '运营合作', icon: '',},
        {key: '/home/aboutUs/teams', title: '产研团队', icon: '',},
        {key: '/home/aboutUs/career', title: '职位招聘', icon: '',},
      ]
    },
    {
      title: '账号信息',
      icon: 'user',
      key: '/home/account'
    },

  ]

  
class Index extends React.Component {

  componentDidMount() {
    const userId = Cookies.get('userId')
        if (!userId) {
            window.location="/login"
        }
  }

  chooseLang = (value) => {
    // windows.loca
    // location.reload(true)
    // window.lang = value
    // alert(value)
    // window.location.reload();

    Cookies.set('lang',value)
    window.location.reload();
  }

  exitLogin = () => {
    Cookies.remove('userId')
    window.location="/login"
  }
  
    render() {
      const lang = Cookies.get('lang')
        return (
            <div id='page'>
            <Layout>
              <Sider trigger={null} >
                <div style={{height: '100vh',overflowY:'scroll'}}>
                    <div style={styles.logo}>
                      <img style={{height:25,width:25,}} alt="logo" className="logoImg" src={logo}></img>
                      <span style={{fontSize:18, color:'#FFF', fontWeight:600}}>图思灵</span>
                    </div>
                    <CustomMenu menus={menus}/>
                </div>
              </Sider>
              <Layout>
                <Header style={{background: '#fff', padding: '0 16px', display:'flex',flexDirection:'row', alignItems:'center'}}>
                  <Select defaultValue={lang? lang : "cn"} style={{ width: 120 }} onChange={this.chooseLang}>
                    <Option value="cn">中文</Option>
                    <Option value="en">英文</Option>
                  </Select>
                  <div style={{display:'flex', flexDirection:'row-reverse', flex:1}}>
                    <Button type='primary' onClick={this.exitLogin}>退出</Button>
                  </div>
                </Header>
                <Content>
                  <ContentMain/>
                </Content>
                <Footer style={{textAlign: 'center'}}>powered by <a target='_blank' href='https://tuxling.com'>图思灵</a></Footer>
              </Layout>
            </Layout>
          </div>
        );
    }
}

const styles = {
    logo: {
      height: '32px',
      // background: 'rgba(255, 255, 255, .2)',
      margin: '16px'
    }
  }
    
export default Index;
