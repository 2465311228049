import React from 'react'
import './index.css'
import { List, Avatar, Card, Icon, Button, Modal, Drawer, Popconfirm, Checkbox } from 'antd';
// import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import Product from './product'
import { FetchProduct, DeleteProduct, CheckHomePage } from '../../service/api'

export default class Sketch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            drawerVisible: false,
            currentItem: {},
            products: [],
            homePage: 0,
        }
    }

    componentDidMount() {
        this.fetchProducts()
    }

    fetchProducts = () => {
        FetchProduct().then(rsp => {
            if (rsp.code == 200) {
                this.setState({
                    products: rsp.data
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    deleteProduct(id){
        const param = {
            id: id,
        }
        DeleteProduct(param).then(rsp => {
            console.log(rsp)
            if (rsp.code == 200) {
                this.fetchProducts()
            }
        }).catch(err => {
            alert("删除失败")
        })
    }
    handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };

    submitNewProduct = () => {
        this.setState({
            visible: false
        })
    }
    addNew() {
        // this.setState({
        //     visible: true,
        // })
    }

    showDrawer(item) {
        this.setState({
            drawerVisible: true,
            currentItem: item,
        })
    }

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
      };

    afterSaveRefresh = () => {
        //close
        this.onClose()
        //
        this.fetchProducts()
    }

    getFirstPic = (item) => {
        if (item.images && item.images != "") {
            const imgArr = JSON.parse(item.images)
            return imgArr.length > 0 ? imgArr[0].url : ""
        }
    }
    setHomePage= (checked, id) => {
        const homePage = checked ? 1 : 0
        const param = {
            homePage,
            id: id
        }
        CheckHomePage(param).then(rsp => {
            this.fetchProducts()
        })
    }
    render() {
        const IconText = ({ type, text }) => (
            <span>
              <Icon type={type} style={{ marginRight: 8 }} />
              {text}
            </span>
          );

        return (
            <div>
                <Card>
                    <Button type="primary" onClick={() => this.showDrawer({})}>新增产品</Button>
                </Card>
            
                <Card>
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={this.state.products}
                        renderItem={item => (
                        <List.Item
                            key={item.title}
                            actions={[
                                <a onClick={() => this.showDrawer(item)}><IconText type="edit-o" text="编辑" key="list-vertical-like-o" /></a>,
                                <Popconfirm
                                    title="确定要删除吗? 别后悔哈"
                                    onConfirm={() => { this.deleteProduct(item.id) }}
                                    // onCancel={cancel}
                                    okText="删除"
                                    cancelText="取消"
                                >
                                    <a ><IconText type="delete" text="删除" key="list-vertical-star-o" /></a>
                                </Popconfirm>
                                ,
                                <Checkbox checked={item.homePage == 1 ? true : false} onChange={(e) => this.setHomePage(e.target.checked, item.id)}>上首页</Checkbox>
                            ]}
                            extra={
                            <img
                                style={{height: 120, maxWidth: 300}}
                                alt="logo"
                                src={this.getFirstPic(item)}
                            />
                            }
                        >
                            <List.Item.Meta
                            title={item.serialNo}
                            description={item.name}
                            />
                            <pre>{item.description}</pre>
                        </List.Item>
                        )}
                    />
                </Card>

                <Drawer
                    title="新增/编辑产品"
                    placement="right"
                    width={1000}
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.drawerVisible}
                    >
                    <Product product={this.state.currentItem} refresh={this.afterSaveRefresh.bind(this)}></Product>
                 </Drawer>

            </div>
        )
    }
}