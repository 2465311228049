
import Cookies from 'js-cookie'

//const host = "http://117.78.28.23:9067/tuthinking/api/"
// const host = "http://localhost:9067/tuthinking/api/"
const host = "http://offical.tuxling.com/tuthinking/api/"

export const UploadUrl = host + "upload"
export const UploadFileUrl = host + "uploadFile"

function myFetch(url, data) {
  let lang = Cookies.get('lang')
  if (!lang) {
    lang = 'cn'
  }
  return fetch(host + url + "?lang=" + lang, data)
}

export function Login(data) {
  console.log("----: ", data)
    return myFetch('user/login',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function UpdateUserPwd(data) {
  console.log("----: ", data)
    return myFetch('user/update',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}



export function FetchMessages(data) {
  // alert()
  // console.log("----: ",window.lang)
    return myFetch('message/list',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function CreateNews(data) {
  console.log("----: ", data)
    return myFetch('news/create',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}

export function FetchNews(data) {
  console.log("----: ", data)
    return myFetch('news/list',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function DeleteNews(data) {
  console.log("----: ", data)
    return myFetch('news/delete',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function CreateProduct(data) {
  console.log("----: ", data)
    return myFetch('products/create',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function FetchProduct() {
    return myFetch('products/list',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function DeleteProduct(data) {
  return myFetch('products/delete',{
    method:'POST',
    headers:{
      'Content-Type':'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  //   mode:'cors',
  //   cache:'default'
  })
   .then(res =>res.json())
}

export function CheckHomePage(data) {
  return myFetch('products/checkHomePage',{
    method:'POST',
    headers:{
      'Content-Type':'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  //   mode:'cors',
  //   cache:'default'
  })
   .then(res =>res.json())
}

export function UpdateSliders(data) {
  return myFetch('sliders/create',{
    method:'POST',
    headers:{
      'Content-Type':'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  //   mode:'cors',
  //   cache:'default'
  })
   .then(res =>res.json())
}


export function FetchSliders() {
  return myFetch('sliders/list',{
    method:'POST',
    headers:{
      'Content-Type':'application/json;charset=UTF-8'
    },
    // body: JSON.stringify(data)
  //   mode:'cors',
  //   cache:'default'
  })
   .then(res =>res.json())
}


export function DeleteSliders(data) {
  return myFetch('sliders/delete',{
    method:'POST',
    headers:{
      'Content-Type':'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  //   mode:'cors',
  //   cache:'default'
  })
   .then(res =>res.json())
}



export function CreateAboutUs(data) {
  console.log("----: ", data)
    return myFetch('aboutUs/create',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}


export function FetchAboutUs() {
    return myFetch('aboutUs/list',{
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
    //   mode:'cors',
    //   cache:'default'
    })
     .then(res =>res.json())
}
