import React, { Component } from 'react'
import { message, Form, Icon, Input, Button, Checkbox } from 'antd';
import logo from '../../images/logo.ico';
import './index.css'
import Cookies from 'js-cookie'
import { Login } from '../../service/api'

const FormItem = Form.Item;

class LoginPageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          txt: '',
        };
    }
    componentDidMount() {
        
    }
    
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        if (!err) {
            console.log('Received values of form: ', values);
            const username = values['username'];
            const password = values['password'];
            const data = { username, password };
            console.log(data);

            Login(data).then(res => {
                if (res.code == 200) {
                    Cookies.set('userId', username, { expires: 0.5 })
                    window.location="/home"
                } else {
                    message.error(res.message)
                }
            }).catch(err => {
                console.log(err)
            })
            // authUser(data).then(resp => {
            // if (resp.ok) {
            //     window.location = '/#/home/';
            // } else {
            //     this.setState({
            //     txt: 'invalid user',
            //     });
            // }
            // });
            
        }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{height:'100vh', display:'flex', flexDirection:'column', }}>
            <div className="login-wrap">
                 <div className="loginView">
                <Form onSubmit={this.handleSubmit.bind(this)} className="loginForm">
                    <div style={{textAlign:'center', color:'#000', marginBottom: 20}}>
                        {/* <img alt="logo" class="logoImg" src={logo}></img> */}
                        <span className="companyName">图思灵智慧医疗</span>
                        <h5>官网后端管理系统</h5>
                    </div>
                    
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="账号"
                                size="large"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="密码"
                                size="large"
                                // style={{ marginRight: 10}}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button block size="large" type="primary" htmlType="submit" className="loginButton">
                            登  录
                        </Button>
                    </Form.Item>
                </Form>
                
                </div>
                
            </div>
            <span>test</span>
            </div>
        )
    }
}

// let LoginPage = Form.create()(LoginPageForm)
// export default LoginPage

export default Form.create({ name: 'LoginPage' })(LoginPageForm);
